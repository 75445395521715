import { CleaveOptions } from "cleave.js/options";
import Cleave from "cleave.js/react";
import React, { Component } from "react";
import styled from "styled-components/macro";

import {
    border,
    colors,
    defaultRadius,
    distances,
    palette,
} from "../../styles/constants";
import { InputValidation } from "./validation";

import { Label } from "../Typography";

export type OnChangeFunction = (event: Event) => void;

interface InputProps {
    className?: string;
    name: string;
    label?: any;
    placeholder: string;
    value: string;
    onChange: (
        e: React.FormEvent<HTMLInputElement> &
            React.ChangeEvent<HTMLInputElement>,
    ) => void;
    onBlur: (
        e: React.FormEvent<HTMLInputElement> &
            React.FocusEvent<HTMLInputElement>,
    ) => void;
    disabled?: boolean;
    type?: string;
    validation?: InputValidation;
    prefix?: string;
    options: CleaveOptions;
    inputMode?:
        | "none"
        | "text"
        | "tel"
        | "url"
        | "email"
        | "numeric"
        | "decimal"
        | "search";
    autoComplete?: string;
    autoFocus?: boolean;
    postfix?: string;
    required?: boolean;
    readOnly?: boolean;
    testId?: string;
    mb?: string;
    messageStyles?: React.CSSProperties;
}
interface InputState {
    cleave: any;
}

class Input extends Component<InputProps, InputState> {
    constructor(props: InputProps, context: any) {
        super(props, context);

        this.state = {
            cleave: undefined,
        };
        this.onInputInit = this.onInputInit.bind(this);
    }

    onInputInit(cleave: React.ReactInstance) {
        this.setState({ cleave });
    }

    componentDidUpdate(prevProps: InputProps) {
        if (prevProps.value !== this.props.value) {
            if (this.state.cleave) {
                this.state.cleave.setRawValue(this.props.value || "");
            }
        }
    }

    render() {
        let input: any = null;
        const props = this.props;

        return (
            <Wrapper {...{ className: props.className }}>
                {props.label && (
                    <Label htmlFor={props.name}>{props.label}</Label>
                )}
                <InputElement
                    options={props.options}
                    value={props.value}
                    id={props.name}
                    type={props.type || "text"}
                    name={props.name}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    htmlRef={(elem) => {
                        input = elem;
                    }}
                    onInit={this.onInputInit}
                    onChange={(e) => {
                        if (
                            e.currentTarget &&
                            e.currentTarget.value !== props.value
                        ) {
                            props.onChange(e);
                        }
                    }}
                    autoFocus={props.autoFocus}
                    onBlur={props.onBlur}
                    className={props.validation ? props.validation.state : ""}
                    inputMode={props.inputMode || "text"}
                    autoComplete={props.autoComplete}
                    prefix={props.prefix}
                    postfix={props.postfix}
                    required={props.required}
                    readOnly={props.readOnly}
                    data-testid={props.testId}
                    mb={props.mb}
                />
                {props.prefix && (
                    <Prefix
                        onClick={() => {
                            input && input.focus();
                        }}
                        hasLabel={props.label !== undefined}
                    >
                        {props.prefix}
                    </Prefix>
                )}
                {props.postfix && (
                    <Postfix
                        onClick={() => {
                            input && input.focus();
                        }}
                        hasLabel={props.label !== undefined}
                    >
                        {props.postfix}
                    </Postfix>
                )}
                {props.validation && props.validation.message && (
                    <Message
                        htmlFor={props.name}
                        className={props.validation.state}
                        labelExists={props.label?.length > 0}
                        style={props.messageStyles}
                    >
                        {props.validation.message}
                    </Message>
                )}
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
    &.stretch {
        width: 100%;
    }
    &.huge input {
        font-size: 44px;
    }
    &.huge label.invalid {
        top: 101px;
    }
    &.stretch {
        width: 100%;
    }
    &.no-margin {
        input {
            margin-bottom: 0;
        }
    }
`;

interface PrefixProps {
    hasLabel: boolean;
}
const Prefix = styled.label<PrefixProps>`
    position: absolute;
    top: ${(props) => (props.hasLabel ? "38px" : "10px;")};
    left: ${distances.small};
    color: ${colors.textSecondary};
    cursor: text;
`;

const Postfix = styled.label<PrefixProps>`
    position: absolute;
    top: ${(props) => (props.hasLabel ? "38px" : "10px;")};
    right: ${distances.small};
    color: ${colors.textSecondary};
    cursor: text;
`;

type InitHandler = (owner: React.ReactInstance) => void;
interface ChangeEvent<T> extends React.ChangeEvent<T> {
    target: { rawValue: string } & EventTarget & T;
}
type ChangeEventHandler<T = Element> = React.EventHandler<ChangeEvent<T>>;

interface InputElementProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    onInit?: InitHandler;
    options: CleaveOptions;
    htmlRef?: (i: any) => void;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    postfix?: string;
    mb?: string;
}

const InputElement = styled(Cleave)<InputElementProps>`
    border: none;
    outline: none;
    border: ${border.normal} solid ${palette.neutral[400]};
    border-radius: ${defaultRadius};
    background: ${colors.background};
    padding: ${distances.tiny} ${distances.small12};
    width: 100%;
    -moz-appearance: textfield;
    margin-bottom: ${(props) => props.mb || distances.normal};
    min-height: 40px;
    font-feature-settings: 'tnum';

    ${(props) => (props.inputMode === "numeric" ? "text-align: right;" : "")};

    ${(props) => (props.prefix ? `padding-left: calc(${distances.small} + ${props.prefix.length * 12}px);` : "")}
    ${(props) => (props.postfix ? `padding-right: calc(${distances.small} + ${props.postfix.length * 12}px);` : "")}


    &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
    }

    &::placeholder {
        color: ${colors.textSecondary};
    }

    &:focus {
        outline: none;
        border: ${border.normal} solid ${palette.primary[300]};
        box-shadow: 0 0 0 3px ${palette.primary[100]};
    }

    &.invalid,
    &:focus .invalid {
        border: ${border.normal} solid ${palette.destructive[300]};
    }

    &.invalid:focus {
        box-shadow: 0 0 0 3px ${palette.destructive[100]};
    }

    &.valid,
    &:focus .valid {
        border: ${border.normal} solid ${palette.success[300]};
    }

    &.valid:focus {
        box-shadow: 0 0 0 3px ${palette.success[100]};
    }

    &.warning,
    &:focus .warning {
        border: ${border.normal} solid ${palette.warning[300]};
    }

    &.warning:focus {
        box-shadow: 0 0 0 3px ${palette.warning[100]};
    }

    &:disabled {
        background: ${palette.neutral[50]};
        border: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[500]};
    }
`;

const Message = styled.label<{ labelExists: boolean }>`
    position: absolute;
    top: ${(props) => (props.labelExists ? "72px" : "33px")};
    left: 0;
    font-size: 14px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }

    &.warning {
        color: ${colors.warning};
    }
`;

export default Input;
