import styled from "styled-components/macro";
import { defaultRadius, distances, palette } from "../../styles/constants";
import { LeftPane, RightPane, Wrapper } from "../TopBar/elements";
import View from "../View";

export default function TopBarPlaceholder() {
    return (
        <Wrapper>
            <View width="100%" justify="space-between" className="main-toolbar">
                <LeftPane>
                    <View gap={distances.tiny} alignItems="center">
                        <Skeleton $width="32px" $height="32px" />
                        <View
                            direction="column"
                            gap={distances.micro}
                            justify="center"
                        >
                            <Skeleton $width="80px" $height="12px" />
                            <Skeleton $width="80px" $height="12px" />
                        </View>
                    </View>
                </LeftPane>
                <RightPane>
                    <Skeleton $width="32px" $height="32px" />
                    <Skeleton $width="32px" $height="32px" />
                    <Skeleton $width="32px" $height="32px" />
                </RightPane>
            </View>
        </Wrapper>
    );
}

const Skeleton = styled.div<{
    $width?: string;
    $height?: string;
}>`
    background: ${palette.neutral[100]};
    height: ${(props) => props.$height || "20px"};
    width: ${(props) => props.$width || "100px"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: ${defaultRadius};
    animation: shimmer 2s infinite linear;
    background-image: linear-gradient(
        to right,
        ${palette.neutral[100]},
        ${palette.neutral[200]},
        ${palette.neutral[100]}
    );
    background-size: 1000px 100%;
    position: relative;
    overflow: hidden;
    @keyframes shimmer {
        0% {
            background-position: -1000px;
        }
        100% {
            background-position: 1000px;
        }
    }
`;
