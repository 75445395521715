import { forwardRef } from "react";

export const ClockIcon = forwardRef<
    SVGSVGElement,
    React.SVGProps<SVGSVGElement>
>((props, ref) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        {...props}
        ref={ref}
    >
        <path
            d="M7.99999 6.02319V8.51208L9.86666 10.3787M13.6 8.51208C13.6 11.6049 11.0928 14.1121 7.99999 14.1121C4.9072 14.1121 2.39999 11.6049 2.39999 8.51208C2.39999 5.41928 4.9072 2.91208 7.99999 2.91208C11.0928 2.91208 13.6 5.41928 13.6 8.51208Z"
            stroke="currentColor"
            strokeWidth="1.336"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
));

ClockIcon.displayName = "ClockIcon";
