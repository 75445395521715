import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import * as Sentry from "@sentry/react";
import Root from "../../../components/Root";
import LoginPage from "../components/LoginPage";

import RedirectToPullRequestRoute from "../../../RedirectToPullRequestRoute";
import NetworkIndicator from "../../../common/components/NetworkIndicator";
import Main from "../../../components/Main";
import { PUBLIC_URL } from "../../../env";
import UncaughtComponentError from "../../../errors/components/UncaughtComponentError";

const basename = PUBLIC_URL || undefined;
interface WithErrorState {
    hasError: boolean;
}

const LoginContainer = () => {
    return (
        <React.StrictMode>
            <Suspense fallback={<>Loading...</>}>
                <Root>
                    <Main noPadding>
                        <BrowserRouter basename={basename}>
                            <LoginRoutes />
                        </BrowserRouter>
                    </Main>
                    <NetworkIndicator />
                </Root>
            </Suspense>
        </React.StrictMode>
    );
};

class LoginRoutes extends React.Component<any, WithErrorState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error: any, info: any) {
        console.error(error, info);
        Sentry.captureException(error);
        this.setState({ hasError: true });
    }
    render() {
        if (this.state.hasError) {
            return <UncaughtComponentError />;
        }
        return (
            <Switch>
                <Route path={"/_pr"} component={RedirectToPullRequestRoute} />
                <Route>
                    <LoginPage />
                </Route>
            </Switch>
        );
    }
}

export default LoginContainer;
