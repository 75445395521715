import React from "react";
import styled from "styled-components/macro";

type ViewProps = {
    gap?: React.CSSProperties["gap"];
    justify?: React.CSSProperties["justifyContent"];
    alignItems?: React.CSSProperties["alignItems"];
    wrap?: React.CSSProperties["flexWrap"];
    alignSelf?: React.CSSProperties["alignSelf"];
    display?: React.CSSProperties["display"];
    direction?: React.CSSProperties["flexDirection"];
    position?: React.CSSProperties["position"];
    p?: React.CSSProperties["padding"];
    px?: React.CSSProperties["paddingInline"];
    py?: React.CSSProperties["paddingBlock"];
    pl?: React.CSSProperties["paddingInlineStart"];
    pr?: React.CSSProperties["paddingInlineEnd"];
    pt?: React.CSSProperties["paddingBlockStart"];
    pb?: React.CSSProperties["paddingBlockEnd"];
    m?: React.CSSProperties["margin"];
    mx?: React.CSSProperties["marginInline"];
    my?: React.CSSProperties["marginBlock"];
    ml?: React.CSSProperties["marginInlineStart"];
    mr?: React.CSSProperties["marginInlineEnd"];
    mt?: React.CSSProperties["marginBlockStart"];
    mb?: React.CSSProperties["marginBlockEnd"];
    width?: React.CSSProperties["width"];
    height?: React.CSSProperties["height"];
    minWidth?: React.CSSProperties["minWidth"];
    minHeight?: React.CSSProperties["minHeight"];
    maxWidth?: React.CSSProperties["maxWidth"];
    maxHeight?: React.CSSProperties["maxHeight"];
    flex?: React.CSSProperties["flex"];
    color?: React.CSSProperties["color"];
    bg?: React.CSSProperties["background"];
    border?: React.CSSProperties["border"];
    borderColor?: React.CSSProperties["borderColor"];
    radius?: React.CSSProperties["borderRadius"];
    top?: React.CSSProperties["top"];
    bottom?: React.CSSProperties["bottom"];
    left?: React.CSSProperties["left"];
    right?: React.CSSProperties["right"];
};

const reactProps = [
    "gap",
    "justify",
    "alignItems",
    "wrap",
    "alignSelf",
    "display",
    "direction",
    "position",
    "p",
    "px",
    "py",
    "pl",
    "pr",
    "pt",
    "pb",
    "m",
    "mx",
    "my",
    "ml",
    "mr",
    "mt",
    "mb",
    "width",
    "height",
    "minWidth",
    "minHeight",
    "maxWidth",
    "maxHeight",
    "flex",
    "color",
    "bg",
    "border",
    "borderColor",
    "radius",
    "top",
    "bottom",
    "left",
    "right",
    "background",
    "theme",
];

const View = styled.div
    .withConfig<ViewProps>({
        shouldForwardProp: (prop) => !reactProps.includes(prop),
    })
    .attrs<ViewProps>((props: ViewProps) => ({
        display: "flex",
        direction: "row",
        justify: "flex-start",
        alignItems: "center",
        wrap: "nowrap",
        alignSelf: "stretch",
        position: "relative",
        width: "auto",
        height: "auto",
        minWidth: "auto",
        minHeight: "auto",
        maxWidth: "none",
        maxHeight: "none",
        flex: "initial",
        color: "inherit",
        background: "transparent",
        ...props,
    }))<ViewProps>`
    ${(props) => parseProps(props)}
`;

const parsePropValue = <T,>(value: T) => {
    if (typeof value === "number") {
        return `${value}px`;
    }

    return value;
};

const parsePropKey = (key: keyof ViewProps | string) => {
    switch (key) {
        case "p":
            return "padding";
        case "px":
            return "padding-inline";
        case "py":
            return "padding-block";
        case "pl":
            return "padding-inline-start";
        case "pr":
            return "padding-inline-end";
        case "pt":
            return "padding-block-start";
        case "pb":
            return "padding-block-end";
        case "m":
            return "margin";
        case "mx":
            return "margin-inline";
        case "my":
            return "margin-block";
        case "ml":
            return "margin-inline-start";
        case "mr":
            return "margin-inline-end";
        case "mt":
            return "margin-block-start";
        case "mb":
            return "margin-block-end";
        case "direction":
            return "flex-direction";
        case "alignItems":
            return "align-items";
        case "justify":
            return "justify-content";
        case "wrap":
            return "flex-wrap";
        case "alignSelf":
            return "align-self";
        case "borderColor":
            return "border-color";
        case "maxWidth":
            return "max-width";
        case "maxHeight":
            return "max-height";
        case "minWidth":
            return "min-width";
        case "minHeight":
            return "min-height";
        case "radius":
            return "border-radius";
        case "bg":
            return "background";
        default:
            return key;
    }
};

const parseProps = (props: ViewProps): string => {
    return Object.entries(props)
        .map(([key, value]) => {
            return `${parsePropKey(key)}: ${parsePropValue(value)};`;
        })
        .join("\n");
};

export default View;
