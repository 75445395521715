import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { logout, setRedirects } from "../../../auth/cognito";
import { Button } from "../../../components/Buttons";
import Modal from "../../../components/Modal";
import { P } from "../../../components/Typography";
import { ClockIcon } from "../../../giftcards/list/components/icons/Clock";
import { distances } from "../../../styles/constants";

const InvalidSession = ({ t }: WithTranslation) => (
    <Modal
        solidBackdrop
        title={t("errors.invalid_session.title")}
        centeredHeader
        icon={<ClockIcon />}
    >
        <ErrorWrapper>
            <P>{t("errors.invalid_session.description")}</P>
        </ErrorWrapper>
        <Button
            className="stretch"
            onClick={() => {
                setRedirects(window.location.pathname, window.location.search);
                logout(false);
            }}
        >
            {t("errors.invalid_session.call_to_action")}
        </Button>
    </Modal>
);

const ErrorWrapper = styled.div`
    padding-top: ${distances.tiny};
    padding-bottom: ${distances.normal24};
    font-size: 14px;
    text-align: center;
`;

export default withTranslation()(InvalidSession);
