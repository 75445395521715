import { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useSyncExternalStore } from "use-sync-external-store/shim";
import Icon from "../../components/Icons";
import { Title } from "../../components/Page";
import { P } from "../../components/Typography";
import { distances, palette } from "../../styles/constants";

type NetworkStatusEnum = "loading" | "online" | "offline";

const getSnapshot = () => {
    try {
        return navigator.onLine;
    } catch {
        // assume online if we can't determine the status
        return true;
    }
};

const subscribe = (callback: () => void) => {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
        window.removeEventListener("online", callback);
        window.removeEventListener("offline", callback);
    };
};

const useOnline = (): NetworkStatusEnum => {
    const isOnline = useSyncExternalStore(subscribe, getSnapshot);

    if (isOnline === undefined) {
        return "loading";
    }

    return isOnline ? "online" : "offline";
};

const NetworkIndicator = memo(() => {
    const { t } = useTranslation();
    const status = useOnline();
    return (
        <Wrapper className={status}>
            <Box>
                <IconWrapper>
                    <Icon icon="close" />
                </IconWrapper>
                <Title>{t("network_indicator.title")}</Title>
                <P color={palette.white}>{t("network_indicator.subtitle")}</P>
            </Box>
        </Wrapper>
    );
});

NetworkIndicator.displayName = "NetworkIndicator";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 999999999;
    transition: all 0.2s ease;
    user-select: none;
    color: ${palette.white};
    background: rgba(0,0,0,0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    &.offline {
        pointer-events: all;
        opacity: 1;
        visibility: visible;
    }
    &.online, &.loading {
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        & > * {
            display: none;
        }
    }
`;

const Box = styled.div`
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const IconWrapper = styled.div`
    padding: ${distances.tiny};
    margin-bottom: ${distances.tiny};
    svg {
        width: 48px;
        height: 48px;
    }
`;

export default NetworkIndicator;
